import React, { useState } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";

import TransparentFooter from "components/Footers/TransparentFooter.js";
import { Card, CardBody, CardImg, Spinner } from "reactstrap";
import { useEffect } from "react";

function Index() {
  const [mainText, setMainText] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [planningURL, setPlanningURL] = useState()

  const fetchData = async () => {
    var data = await fetch(`https://millenium-marles.firebaseio.com/mainPage.json`).then(res => {
      return res.json();
    });
    if (data != null) {
      setMainText(data.mainText)
      setPlanningURL(data.planningURL)

    } else {
    }
  }
  useEffect(() => {
    fetchData().then(setIsLoaded(true))
  }, [])
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar backgroundColor="navbar-transparent" color="info" />
      <div className="wrapper">
        <IndexHeader />
        <div className="main" style={{ maxWidth: 1000, alignSelf: 'center', alignItems: 'center'}}>
          {isLoaded ?
            <>
              <Card>
                <CardBody style={{ textAlign: 'left' }}>
                  {
                    mainText && <p dangerouslySetInnerHTML={{ __html: mainText }} />
                  }
                </CardBody>
              </Card>
              <Card>
                <CardImg
                  src={planningURL}
                  alt=''
                  style={{ maxWidth: 1000, alignSelf: 'center' }} />
              </Card>
            </>
            :
            <Spinner type="grow" color="primary" />
          }
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default Index;
