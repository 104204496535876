import React, { useState, useEffect } from "react";

import firebase from 'firebase/app';
import 'firebase/auth';

// core components
import IndexNavbar from "components/Navbars/DarkNavbar";
import TransparentFooter from "components/Footers/TransparentFooter";
import Dashboard from "components/Dashboard";
import DashboardAdmin from "components/DashboardAdmin";
import Login from "components/Login";

function Admin() {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  const [sectionId, setSectionId] = useState()
  const [isSigned, setisSigned] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setSectionId(getSectionId)
      setisSigned(true)
    }
  });

  const getSectionId = () => {
    switch (firebase.auth().currentUser.uid) {
      case "5kz97qQRaFbsUsIJBDVUz56Zi2z2":
        setIsAdmin(false)
        return 'tennisdetable'

      case "v6rXxZpRogVuPbIzoWTT8M7zY0o1":
        setIsAdmin(false)
        return 'expression'

      case "sNPaVq09uWhJlEoLQto40XMnyHB3":
        setIsAdmin(false)
        return 'pilate'

      case "tZTh2RBlbsMMizMruPT8llnHtjx2":
        setIsAdmin(false)
        return 'gym'

      case "PD7nD6Gel8NOQX1qMZNsGwxwIrm2":
        setIsAdmin(false)
        return 'collection'

      case "k81uRfa71efEZKGd2Qk8xtXllo52":
        setIsAdmin(false)
        return '3age'

      case "vNV3oRX9GLbBbjqbys4m0pprWk52":
        setIsAdmin(false)
        return 'chorale'

      case "9jErboNdESebZUNowaWJbe2Tzkw1":
        setIsAdmin(true)
        return 'main'

      default:
        break
    }
  }
  return (
    <>
      <IndexNavbar color="info" />
      <div className="wrapper">
        <div className="section section-about-us">
          {
            isSigned ?
              isAdmin ?
                <DashboardAdmin />
                :
                <Dashboard sectionId={sectionId} />
              :
              <Login />
          }
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default Admin;
