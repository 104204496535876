import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './firebase'
import { Toaster } from 'react-hot-toast';

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "assets/css/center-radar.css"

// pages for this kit
import Index from "views/Index.js";
import Sections from "views/pages/Sections";
import Admin from "views/pages/Admin";

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Toaster />
    <BrowserRouter>
      <Switch>
        <Switch>
          <Route path="/accueil" render={(props) => <Index {...props} />} />
          <Route
            path="/sections/:sectionId"
            render={(props) => <Sections {...props} />}
          />
          <Route
            path="/admin/:sectionId"
            render={(props) => <Admin {...props} />}
          />
          <Route
            path="/admin"
            render={(props) => <Admin {...props} />}
          />
          <Redirect to="/accueil" />
          <Redirect from="/" to="/accueil" />
        </Switch>
      </Switch>
    </BrowserRouter>
  </FirebaseAppProvider>,
  document.getElementById("root")
);
