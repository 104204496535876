import React, { useState } from "react";
import {
    Container,
    Button,
    CardBody,
    Card,
    Input,
    FormGroup,
    Label,
    UncontrolledCollapse,
    Form,
    UncontrolledTooltip
} from "reactstrap";
import toast from "react-hot-toast";

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function DashboardAdmin() {
    const [editorState, setEditorState] = useState(null)
    const [mainTextEditorState, setMainTextEditorState] = useState(null)
    const [selectedSectionId, setSelectedSectionId] = useState('expression')
    const [sectionData, setSectionData] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [contactKey, setContactKey] = useState()
    const [progressUpload, setProgressUpload] = useState(0)

    const onUpdateAPIkey = () => {
        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`sections/${selectedSectionId}/contactKey`)
                .set(contactKey)
                .then(resolve())
                .catch(reason => reject(reason))
        })
    }
    const onUpdateText = () => {
        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`sections/${selectedSectionId}/text`)
                .set(editorState)
                .then(resolve())
                .catch(reason => reject(reason))
        })
    }
    const onUpdatePlanning = (file) => {
        return new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();
            var metadata = {
                contentType: 'image/jpeg',
              };
            var uploadTask = storageRef.child(`mainPage/planning.jpg`).put(file, metadata);
            uploadTask.on('state_changed',
                (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgressUpload(progress)

                },
                (error) => {
                    toast.error(`Erreur` + error)

                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        firebase.database()
                            .ref(`mainPage/planningURL`)
                            .set(downloadURL)
                            .then(toast.success('Planning mis à jour'))
                            .catch(reason => toast.error(reason))
                    });
                }
            );

        })
    }

    const onUpdateMainText = () => {
        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`mainPage/mainText`)
                .set(mainTextEditorState)
                .then(resolve())
                .catch(reason => reject(reason))
        })
    }

    const fetchData = async (sectionId) => {
        var data = await fetch(`https://millenium-marles.firebaseio.com/sections/${sectionId}.json`).then(res => {
            return res.json();
        });
        console.log(data)
        if (data != null) {

            let text = data.text
            setSectionData(data)
            setEditorState(text)
            setIsLoaded(true)
        }
    }
    const fetchMainText = async () => {
        var data = await fetch(`https://millenium-marles.firebaseio.com/mainPage.json`).then(res => {
            return res.json();
        });
        console.log(data)
        if (data != null) {
            let text = data.mainText
            setMainTextEditorState(text)
        }
    }

    const modules = {
        toolbar: [
            [{ 'header': [3, 4, 5, 6, false] }],
            ['blockquote', 'code-block'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image']
        ]
    };

    return (
        <Container>
            <Card>
                <CardBody>
                    <p>Dashboard Admin</p>
                    <Button color='info' id="sectionPage" style={{ marginBottom: '1rem', width: '20em' }}>
                        Modifier une page de Section
                    </Button>
                    <UncontrolledCollapse toggler="#sectionPage">
                        <Label for="sectionSelect" >Sélectionner une section à modifier</Label>
                        <Input
                            type="select"
                            name="select"
                            id="sectionSelect"
                            onChange={value => {
                                setSelectedSectionId(value.target.value);
                                setIsLoaded(false)
                            }}
                            value={selectedSectionId} >
                            <option value='expression'>Expression</option>
                            <option value='collection'>Collection</option>
                            <option value='chorale'>Chorale</option>
                            <option value='3age'>3 ème Age</option>
                            <option value='tennisdetable'>Tennis de Table</option>
                            <option value='gym'>Gym</option>
                            <option value='pilate'>Pilate</option>
                        </Input>
                        {!isLoaded ?
                            <Button
                                color='info'
                                outline
                                onClick={(e) => {
                                    e.preventDefault()
                                    fetchData(selectedSectionId)
                                }}
                            >
                                Récuperer les données&nbsp;<i className="fas fa-cloud-download-alt" />
                            </Button> :
                            <Container>
                                <Form inline>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for='contactKey'>Clé du formulaire de contact</Label>&nbsp;
                                        <Input
                                            placeholder='Coller ici la clé API'
                                            type='text'
                                            id='contactKey'
                                            defaultValue={sectionData.contactKey}
                                            onChange={value => {
                                                setContactKey(value)
                                            }}
                                        />
                                        <span style={{ textDecoration: "underline", color: "blue" }} href="#" id="tipContactAPI"><i className="fas fa-question-circle" /></span>
                                        <UncontrolledTooltip placement="right" target="tipContactAPI">
                                            Pour ajouter une adresse mail, utiliser le site https://www.staticforms.xyz/
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <Button
                                        color='info'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            toast.promise(onUpdateAPIkey(), {
                                                loading: 'Mise à jour en cours',
                                                success: 'Page mise à jour',
                                                error: 'Une erreur '
                                            })
                                        }}
                                    >
                                        <i className="fas fa-check" />
                                    </Button>
                                </Form>
                                <ReactQuill value={editorState}
                                    onChange={data => setEditorState(data)}
                                    modules={modules}
                                />
                                <Button
                                    color='info'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        console.log(editorState)
                                        toast.promise(onUpdateText(), {
                                            loading: 'Mise à jour en cours',
                                            success: 'Page mise à jour',
                                            error: 'Une erreur '
                                        })
                                    }}
                                >
                                    Mettre à jour
                                </Button>
                            </Container>
                        }
                    </UncontrolledCollapse>
                    <p>{' '}</p>
                    <Button color='info' id="mainPage" style={{ marginBottom: '1rem', width: '20em' }}>
                        Modifier la page d'accueil
                    </Button>
                    <UncontrolledCollapse toggler='#mainPage'>
                        <Card>
                            <CardBody>
                                <Button
                                    color='info'
                                    outline
                                    onClick={(e) => {
                                        e.preventDefault()
                                        fetchMainText()
                                    }}
                                >
                                    Récuperer les données&nbsp;<i className="fas fa-cloud-download-alt" />
                                </Button>
                                <ReactQuill value={mainTextEditorState}
                                    onChange={data => setMainTextEditorState(data)}
                                    modules={modules}
                                />
                                <Button
                                    color='info'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toast.promise(onUpdateMainText(), {
                                            loading: 'Mise à jour en cours',
                                            success: 'Page mise à jour',
                                            error: 'Une erreur '
                                        })
                                    }}
                                >
                                    Mettre à jour
                                </Button>
                            </CardBody>
                        </Card>
                    </UncontrolledCollapse>
                </CardBody>
                <CardBody>
                    <p>Changer le planning</p>
                    <Input
                        type='file'
                        accept="image/jpeg"
                        onChange={
                            file => {
                                onUpdatePlanning(file.target.files[0])
                            }
                        }
                    ></Input><p>{progressUpload} %</p>
                </CardBody>
            </Card>
            <Button
                color="info"
                onClick={(e) => {
                    e.preventDefault()
                    firebase.auth().signOut()
                }}
            >
                <i className="now-ui-icons ui-1_simple-remove" />&nbsp;
                Se deconnecter
            </Button>
        </Container>
    );
}

export default DashboardAdmin;
