import React, { useState, useEffect } from "react";
import {
    Container,
    Button,
    CardBody,
    Card
} from "reactstrap";
import toast from "react-hot-toast";

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Dashboard({ sectionId }) {
    const updateData = () => {
        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`sections/${sectionId}/text`)
                .set(editorState)
                .catch(reason => reject(reason))
                .then(resolve())
        })
    }
    const fetchData = async (sectionId) => {
        var data = await fetch(`https://millenium-marles.firebaseio.com/sections/${sectionId}.json`).then(res => {
            return res.json();
        });
        console.log(data)
        if (data != null) {
            let text = data.text
            seteditorState(text)
        }
    }

    const [editorState, seteditorState] = useState(null)

    const modules = {
        toolbar: [
            [{ 'header': [3, 4, 5, 6, false] }],
            ['blockquote', 'code-block'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image']
        ]
    };
    useEffect(() => {
        fetchData(sectionId)
    }, [sectionId])

    return (
        <Container>
            <Card>
                <CardBody>
                    <Button
                        color="info"
                        onClick={(e) => {
                            e.preventDefault()
                            console.log(editorState)
                            toast.promise(updateData(), {
                                loading: 'Mise à jour en cours',
                                success: 'Page mise à jour',
                                error: 'Une erreur '
                            })
                        }}
                    >
                        <i className="now-ui-icons ui-1_check" />&nbsp;
                        Enregistrer et Publier
                    </Button>
                    <br /><br />
                    <ReactQuill value={editorState}
                        onChange={data => seteditorState(data)}
                        modules={modules}
                    />
                </CardBody>
            </Card>
            <div className="separator separator-primary"></div>
            <h3>Aperçu</h3>
            <Card>
                <CardBody>
                    <p dangerouslySetInnerHTML={{ __html: editorState }} />
                </CardBody>
            </Card>
            <pre><code>{sectionId}</code></pre>
            <Button
                color="info"
                onClick={(e) => {
                    e.preventDefault()
                    firebase.auth().signOut().then(window.location.reload())
                }}
            >
                <i className="now-ui-icons ui-1_simple-remove" />&nbsp;
                Se deconnecter
            </Button>
        </Container>
    );
}

export default Dashboard;
