import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import firebase from 'firebase/app';
import 'firebase/auth';

import toast from "react-hot-toast";

function Login() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [password, setPassword] = useState('')
  const [emailId, setEmailId] = useState('')
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleSignIn = () => {
    let email
    validateEmail(emailId) ? email = `${emailId}`: email = `${emailId}@millenium-marles.fr`
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        toast.success(`Connexion réussie`)
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          errorMessage = "Mot de passe incorrect"
        } else if (errorCode === 'auth/user-disabled') {
          errorMessage = "Ce compte a été désactivé par un administrateur, si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'administrateur"
        } else if (errorCode === 'auth/user-not-found') {
          errorMessage = "Aucun compte n'a été trouvé pour cet identifiant"
        } else if (errorCode === 'auth/invalid-email') {
          errorMessage = "Adresse mail mal formatée"
        } 
        toast.error(`${errorMessage}`)
        console.log(errorCode);
      });
  }
  return (
    <Container>
      <Col className="ml-auto mr-auto" md="4">
        <Card className="card-login card-plain">
          <Form action="" className="form" method="">
            <CardHeader className="text-center">
              <div className="logo-container">
                <img
                  alt="..."
                  src={require("assets/img/now-logo.svg").default}
                ></img>
              </div>
            </CardHeader>
            <CardBody>
              <InputGroup
                className={
                  "no-border input-lg" +
                  (firstFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-user-circle"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Identifiant..."
                  type="text"
                  onChange={(txt) => setEmailId(txt.target.value)}
                  onFocus={() => setFirstFocus(true)}
                  onBlur={() => setFirstFocus(false)}
                ></Input>
              </InputGroup>
              <InputGroup
                className={
                  "no-border input-lg" +
                  (lastFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-key"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Mot de passe..."
                  type="password"
                  onChange={(txt) => setPassword(txt.target.value)}
                  onFocus={() => setLastFocus(true)}
                  onBlur={() => setLastFocus(false)}
                ></Input>
              </InputGroup>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                block
                className="btn-round"
                color="info"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault()
                  handleSignIn()
                }
                }
                size="lg"
              >
                Connexion
              </Button>
              <div className="pull-left">
                <h6>
                  <a
                    className="link"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                  </a>
                </h6>
              </div>
              <div className="pull-right">
                <h6>
                  <a
                    className="link"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                  </a>
                </h6>
              </div>
            </CardFooter>
          </Form>
        </Card>
      </Col>
    </Container>
  );
}

export default Login;
