import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState(props.backgroundColor);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor(props.backgroundColor);
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="sm" color={props.color}>
        <Container>
          <div className="navbar-translate">
            <NavbarBrand

              href="/"
              id="navbar-brand"
            >
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Accueil
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  to='/sections/tennisdetable' tag={Link}
                >
                  <i className="fas fa-table-tennis"></i>
                  &nbsp;
                  Tennis de Table
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/sections/expression' tag={Link}
                >
                  <i className="fas fa-running"></i>
                  &nbsp;
                  Expression
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/sections/pilate' tag={Link}
                >
                  <i className="fas fa-child"></i>
                  &nbsp;
                  Pilate
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/sections/gym' tag={Link}
                >
                  <i className="fas fa-heartbeat"></i>
                  &nbsp;
                  Gym-Tonic
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/sections/collection' tag={Link}
                >
                  <i className="fas fa-palette"></i>
                  &nbsp;
                  Multi-Collection
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/sections/3age' tag={Link}
                >
                  <i className="fas fa-blind"></i>
                  &nbsp;
                  3ème Age
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to='/sections/chorale' tag={Link}
                >
                  <i className="fas fa-music"></i>
                  &nbsp;
                  Chorale
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
